//
// tooltip.js
// Theme module
//
import { Tooltip } from 'bootstrap';

(() => {
    'use strict'

    var tooltipTriggerList = Array.prototype.slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })

})();

require("expose-loader?exposes=$,jQuery!jquery");
require("@popperjs/core");

// Import what we need from bootstrap
import {
    Alert,
    Button,
    Collapse,
    Dropdown,
    Modal,
    Popover,
    Tooltip,
} from 'bootstrap';

// Make available globally
window.Alert = Alert;
window.Button = Button;
window.Collapse = Collapse;
window.Dropdown = Dropdown;
window.Modal = Modal;
window.Popover = Popover;
window.Tooltip = Tooltip;


require("jquery-ujs/src/rails.js");

require("./_clipboard.js");
require("./_selectall.js");
require("./_tooltips.js");
require("./_form-validation.js");
require("./_list.js");
require("./_pricing.js");
require("./_sort.js");

//
// clipboard.js
//
var ClipboardJS = require("clipboard");
import { Tooltip } from 'bootstrap';


(() => {
    'use strict';

    var btns = document.querySelectorAll('button.clipboard, span.clipboard');
    var clipboard = new ClipboardJS(btns);

    clipboard.on('success', function (e) {
        var tooltip = new Tooltip(e.trigger, {
            trigger: 'manual',
            title: 'Copied!',
            }
        );

        tooltip.show();

        setTimeout(function() {
            tooltip.dispose();
        }, 3000);
    });

    clipboard.on('error', function (e) {
        console.log(e);
    });
})();

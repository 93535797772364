//
// pricing.js
// Theme module
//

import { CountUp } from 'countup.js';


(() => {
  'use strict'

  //
  // Variables
  //
  var DURATION = 1;

  var toggles = document.querySelectorAll('[data-toggle="price"]');
  var deviceToggles = document.querySelectorAll('[data-toggle="price-device"]');

  //
  // Functions
  //

  function update(e) {
    var input = e.target;
    var target = input.dataset.target;
    var targets = document.querySelectorAll(target);

    [].forEach.call(targets, function (e) {
      var annual = Number(e.dataset.annual);
      var annualUnit = Number(e.dataset.annualUnit);
      var monthly = Number(e.dataset.monthly);
      var monthlyUnit = Number(e.dataset.monthlyUnit);
      var storageMonthly = Number(e.dataset.storageMonthly);
      var storageYearly = Number(e.dataset.storageYearly);
      var startVal = Number(e.dataset.value);
      var duration = e.dataset.duration ? e.dataset.duration : DURATION;


      var periodID = e.dataset.period;
      var periodToggle = document.getElementById(periodID);
      var periodMonthly = periodToggle.checked;

      var devicesID = e.dataset.devices;
      var devicesFree = e.dataset.devicesFree;
      var devicesSelect = document.getElementById(devicesID);
      var numDevices = devicesSelect.options[devicesSelect.selectedIndex].value;

      var storageID = e.dataset.storage;
      var storageFree = e.dataset.storageFree;
      var storageSelect = document.getElementById(storageID);
      var storageSize = storageSelect.options[storageSelect.selectedIndex].value;

      var checkoutID = e.dataset.checkout;
      var checkoutLink = document.getElementById(checkoutID);

      var checkoutProduct = e.dataset.checkoutProduct;


      // Want to update the url for checkout.
      var url = checkoutLink.href.split('?', 1)[0];
      url += "?product_id=" + checkoutProduct + "&num_devices=" + numDevices + "&storage=" + storageSize + "&monthly=" + periodMonthly;
      checkoutLink.href = url;

      // Calculate price
      var target = 0;

      if(periodMonthly)  {
        target = monthly + (numDevices - devicesFree) * monthlyUnit;
        target += (storageSize - storageFree) * storageMonthly;
      } else {
        target = annual + (numDevices - devicesFree) * annualUnit;
        target += (storageSize - storageFree) * storageYearly;
      }

      e.dataset.value = target;

      var label = (periodMonthly) ? "mo" : "yr";
      var options = {
        startVal: startVal,
        decimalPlaces: 2,
        duration: duration,
        suffix: "<span class=\"h2 align-self-end mb-1\">/" + label + "</span>",
      }

      var countUp = new CountUp(e, target, options);

      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
    });
  }

  function updateDevice(e) {
    var input = e.target;
    var target = input.dataset.target;
    var targets = document.querySelectorAll(target);

    [].forEach.call(targets, function (e) {
      var startVal = Number(e.dataset.value);
      var priceBase = Number(e.dataset.priceBase);
      var priceUnit = Number(e.dataset.priceUnit);
      var duration = e.dataset.duration ? e.dataset.duration : DURATION;

      var devicesID = e.dataset.devices;
      var devicesSelect = document.getElementById(devicesID);
      var numDevices = devicesSelect.options[devicesSelect.selectedIndex].value;

      var checkoutID = e.dataset.checkout;
      var checkoutLink = document.getElementById(checkoutID);

      var checkoutProduct = e.dataset.checkoutProduct;

      // Want to update the url for checkout.
      var url = checkoutLink.href.split('?', 1)[0];
      url += "?product_id=" + checkoutProduct + "&num_devices=" + numDevices;
      checkoutLink.href = url;


      var target = priceBase + (numDevices - 1) * priceUnit;
      e.dataset.value = target;

      var options = {
        startVal: startVal,
        decimalPlaces: 2,
        duration: duration,
      }

      var countUp = new CountUp(e, target, options);

      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }

    });
  }

  //
  // Events
  //

  if (typeof CountUp !== 'undefined' && toggles) {
    for (var i = 0; i < toggles.length; i++) {
      toggles[i].addEventListener('change', function (e) {
        update(e);
      });
    }
  }

  if (typeof CountUp !== 'undefined' && deviceToggles) {
    for (var i = 0; i < deviceToggles.length; i++) {
      deviceToggles[i].addEventListener('change', function (e) {
        updateDevice(e);
      });
    }
  }

})();
//
// list.js
// Theme module
//


(() => {
  'use strict';
  
  const lists = document.querySelectorAll('[data-list]');

  function init(list) {
    const listCheckboxes = list.querySelectorAll('.list-checkbox');
    const listCheckboxAll = list.querySelector('.list-checkbox-all');


    // Checkboxes
    if (listCheckboxes) {
      [].forEach.call(listCheckboxes, function (checkbox) {
        checkbox.addEventListener('change', function () {
          if (listCheckboxAll) {
            listCheckboxAll.checked = false;
          }
        });
      });
    }

    // Checkbox
    if (listCheckboxAll) {
      listCheckboxAll.addEventListener('change', function () {
        [].forEach.call(listCheckboxes, function (checkbox) {
          checkbox.checked = listCheckboxAll.checked;
        });
      });
    }

  }

  if (lists) {
    [].forEach.call(lists, function (list) {
      init(list);
    });
  }


})();



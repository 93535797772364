//
// list.js
// Theme module
//


(() => {
    'use strict';

    const sortables = document.querySelectorAll('[data-sort]');

    function reloadWithQueryStringVars(queryStringVars) {
        const urlParams = new URLSearchParams(window.location.search);

        if(queryStringVars) {
            for (var queryStringVar in queryStringVars) {
                urlParams.set(queryStringVar, queryStringVars[queryStringVar]);
            }
        }

        window.location.href = location.pathname + "?" + urlParams.toString();
    }

    const urlParams = new URLSearchParams(window.location.search);


    var direction = 'asc';
    var currentSortOrder;

    const order = urlParams.get('order');
    if (order) {
        const orderTokens = order.split(" ");
        currentSortOrder = orderTokens[0];

        if (orderTokens.length > 1) {
            direction = orderTokens[1];
        }
    }

    function init(sortable) {
        var value = sortable.dataset.sort;
        if (value == currentSortOrder) {
            sortable.classList.add(direction);
        }

        sortable.addEventListener('click', function (e) {
            e.preventDefault();

            var target = e.target;
            var value = target.dataset.sort;

            if (value == currentSortOrder) {
                if (direction == 'asc') {
                    value += " desc";
                }
            }

            reloadWithQueryStringVars({"order": value});
        });
    }

    if (sortables) {
        [].forEach.call(sortables, function (sortable) {
            init(sortable);
        });
    }
})();



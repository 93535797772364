'use strict';

(() => {
    'use strict'
    var inputs = document.querySelectorAll('input.select-all');
    for (var i = 0, len = inputs.length; i < len; i++) {
        inputs[i].onclick = function () {
            this.select();
        }
    }
})();
